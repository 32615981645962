import React, { ReactElement } from 'react';

interface LocationMarkerSvgProps {
  title: string;
  hideEyeOnPin: boolean;
  classNames?: string;
  focused?: boolean;
  markerIcon: string;
}

export default function LocationMarkerSvg({
  title,
  hideEyeOnPin,
  classNames = '',
  focused,
  markerIcon = 'Eye-V01',
}: LocationMarkerSvgProps): ReactElement {
  let iconGraphic = <g></g>;

  if (markerIcon == 'Eye-V01') {
    //Rendering ECP-eye-green.svg
    iconGraphic = (
      <g>
        <path
          style={{ fill: '#fff' }}
          d="M14.93,9.37c-2.9,0-5.25,2.35-5.25,5.25s2.35,5.25,5.25,5.25,5.25-2.35,5.25-5.25-2.35-5.25-5.25-5.25Zm0,7.27c-1.12,0-2.02-.91-2.02-2.02s.91-2.02,2.02-2.02,2.02,.91,2.02,2.02-.91,2.02-2.02,2.02Z"
        />
        <path
          style={{ fill: '#fff' }}
          d="M5.23,14.95h0c.04-.07,4.49-6.93,9.77-6.93s9.72,6.86,9.77,6.93v-.66c-.04,.07-4.49,6.93-9.77,6.93S5.28,14.36,5.23,14.29v.66Zm9.77,7.5c5.95,0,10.61-7.19,10.8-7.5l.21-.33-.21-.33c-.2-.31-4.85-7.5-10.8-7.5S4.39,13.98,4.2,14.29l-.21,.33,.21,.33c.2,.31,4.85,7.5,10.8,7.5Z"
        />
      </g>
    );
  } else if (markerIcon == 'Eye-V02') {
    //Rendering ECP-eye02-green.svg
    iconGraphic = (
      <g>
        <path
          style={{ fill: '#fff' }}
          d="M14.85,4.12c-5.8,0-10.5,4.7-10.5,10.5s4.7,10.5,10.5,10.5,10.5-4.7,10.5-10.5S20.65,4.12,14.85,4.12Zm0,14.54c-2.23,0-4.05-1.81-4.05-4.05s1.81-4.05,4.05-4.05,4.05,1.81,4.05,4.05-1.81,4.05-4.05,4.05Z"
        />
        <path
          style={{ fill: '#fff' }}
          d="M12.41,14.62c-.89,0-1.6-.72-1.6-1.6s.72-1.6,1.6-1.6,1.6,.72,1.6,1.6-.72,1.6-1.6,1.6Z"
        />
      </g>
    );
  } else if (markerIcon == 'Glasses') {
    //Rendering ECP-glasses-green.svg
    iconGraphic = (
      <g>
        <path
          style={{ fill: '#fff' }}
          d="M27.28,12.19h-.27c-.12-.48-.47-.91-1.46-1.28-1.42-.53-7.25-.43-8.23,.02-.41,.19-.73,.41-.97,.68h-2.8c-.24-.26-.56-.49-.97-.68-.98-.45-6.81-.55-8.23-.02-.99,.37-1.34,.8-1.46,1.28h-.16v3.02h.47c.25,.98,.68,2.03,1.39,2.59,1.32,1.04,6.3,.91,7.25,.48,1.11-.51,1.43-1.36,1.58-2.05,0,0,.35-1.72,.53-2.61,.29-.08,.64-.14,1.02-.14s.71,.06,.99,.13c.18,.88,.53,2.61,.53,2.62,.15,.69,.47,1.54,1.58,2.05,.95,.43,5.94,.56,7.25-.48,.71-.56,1.13-1.61,1.39-2.59h.58v-3.02Zm-14.99,3.79c-.13,.57-.33,.98-.94,1.26-.72,.33-5.2,.35-6.07-.34-.8-.63-1.23-2.74-1.31-3.93,0-.05,0-.1,0-.14,0-.44,0-.54,.77-.84,1.23-.46,6.71-.31,7.36-.02,.7,.32,.8,.66,.81,.97-.09,.43-.62,3.05-.62,3.04Zm13.63-3.16s0,.09,0,.14c-.08,1.19-.51,3.3-1.31,3.93-.87,.69-5.34,.67-6.07,.34-.61-.28-.81-.69-.94-1.26,0,0-.53-2.61-.62-3.04,0-.3,.1-.65,.81-.97,.65-.29,6.13-.45,7.36,.02,.78,.29,.78,.4,.77,.84Z"
        />
        <path
          style={{ fill: '#fff' }}
          d="M7.65,10.04c.61-.82,3.49-4.66,3.95-4.66h.38c.8,0,1.22-1.12,.28-1.12h-.78c-.62,0-5.46,4.6-7.02,6.1,.63-.19,1.86-.29,3.19-.31Z"
        />
        <path
          style={{ fill: '#fff' }}
          d="M22.55,10.04c-.61-.82-3.49-4.66-3.95-4.66h-.38c-.8,0-1.22-1.12-.28-1.12h.78c.62,0,5.46,4.6,7.02,6.1-.63-.19-1.86-.29-3.19-.31Z"
        />
      </g>
    );
  } else if (markerIcon == 'Phoropter') {
    //Rendering ECP-opt-green.svg
    iconGraphic = (
      <g>
        <g>
          <path
            style={{ fill: '#fff' }}
            d="M13.27,13.78h.01V6.88h-4.98v1.28h2.04v1.32c-.69-.34-1.46-.52-2.28-.52-2.88,0-5.22,2.34-5.22,5.22,0,.78,.17,1.52,.48,2.18l-.9,.42v1.99l1.95-.91c.94,.95,2.25,1.53,3.69,1.53,2.88,0,5.22-2.34,5.22-5.22,0-.13,0-.26-.02-.39Zm-5.2,4.03c-2.01,0-3.64-1.63-3.64-3.64s1.63-3.64,3.64-3.64,3.64,1.63,3.64,3.64-1.63,3.64-3.64,3.64Z"
          />
          <path
            style={{ fill: '#fff' }}
            d="M16.71,14.18c0,2.88,2.34,5.22,5.22,5.22,1.44,0,2.74-.59,3.69-1.53l1.95,.91v-1.99l-.9-.42c.31-.67,.48-1.4,.48-2.18,0-2.88-2.34-5.22-5.22-5.22-.82,0-1.59,.19-2.28,.52v-1.32h2.04v-1.28h-4.98v6.92h.01c0,.12-.02,.25-.02,.38Zm1.58,0c0-2.01,1.63-3.64,3.64-3.64s3.64,1.63,3.64,3.64-1.63,3.64-3.64,3.64-3.64-1.63-3.64-3.64Z"
          />
        </g>
        <path
          style={{ fill: '#fff' }}
          d="M14.96,8.66c-.45,0-.82,.37-.82,.82s.37,.82,.82,.82,.82-.37,.82-.82-.37-.82-.82-.82Zm0,1.12c-.16,0-.3-.13-.3-.3s.13-.3,.3-.3,.3,.13,.3,.3-.13,.3-.3,.3Z"
        />
        <path
          style={{ fill: '#fff' }}
          d="M14.96,6.2c-.45,0-.82,.37-.82,.82s.37,.82,.82,.82,.82-.37,.82-.82-.37-.82-.82-.82Zm0,1.23c-.22,0-.4-.18-.4-.4s.18-.4,.4-.4,.4,.18,.4,.4-.18,.4-.4,.4Z"
        />
      </g>
    );
  } else if (markerIcon == 'Clarkson-style Eye') {
    iconGraphic = (
      <g>
        <circle
          className="st1"
          style={{ fill: 'none', stroke: '#FFFFFF', strokeWidth: 1.5, strokeMiterlimit: 10 }}
          cx="15.18"
          cy="15.24"
          r="1.96"
        />
        <circle
          className="st1"
          style={{ fill: 'none', stroke: '#FFFFFF', strokeWidth: 1.5, strokeMiterlimit: 10 }}
          cx="15.18"
          cy="15.24"
          r="5.32"
        />
        <path
          className="st2"
          style={{
            fill: 'none',
            stroke: '#FFFFFF',
            strokeWidth: 1.5,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          d="M25.7,15.24c0,0-3.68,5.31-10.66,5.31S4.38,15.23,4.38,15.23v0.01c0,0,3.68-5.32,10.66-5.32 S25.7,15.23,25.7,15.24L25.7,15.24z"
        />
      </g>
    );
  } else if (markerIcon == 'none') {
    // Rendering a small white circle for a blank markerIcon
    iconGraphic = (
      <g>
        <circle cx="15" cy="15" r="8" fill="white" />
      </g>
    );
  }

  return (
    <svg
      width="46"
      height="64"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 42"
      style={{
        position: 'absolute',
        transform: `${focused ? 'translate(-50%, -100%)' : 'translate(-50%, -90%)'} ${
          focused ? 'scale(1, 1)' : 'scale(.75, .75)'
        }`,
      }}
      className={`${classNames} fill-current transition ease-in-out duration-200 ${
        focused ? 'focused' : ''
      }`}
    >
      <defs></defs>
      <g>
        <title>{title}</title>
        <path
          style={{ fill: focused ? '#19BFD2' : '#afaeae' }}
          d="M30,15c0,8.28-15,27-15,27,0,0-15-18.72-15-27S6.72,0,15,0s15,6.72,15,15Z"
        />
        {!hideEyeOnPin ? iconGraphic : ''}
      </g>
    </svg>
  );
}
