import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import {
  Map as MapLibreMap,
  Marker,
  LngLatBounds,
  NavigationControl,
  FullscreenControl,
} from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import LocationMarkerSvg from './MapMarker/LocationMarkerSvg';

interface MapOption {
  fullscreenControl?: boolean;
  zoomControl?: boolean;
  unBlockOnClick?: boolean;
}
interface StaticMapProps {
  staticLocations?: { lat: number; lng: number }[];
  defaultCenter?: { lat: number; lng: number };
  mapOptions?: MapOption;
  zoom?: number;
}

export default function StaticMap({
  staticLocations,
  defaultCenter,
  zoom = 7,
  mapOptions = { fullscreenControl: true, zoomControl: true, unBlockOnClick: true },
}: StaticMapProps): React.ReactElement {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<MapLibreMap | null>(null);

  const initializeMap = (): void => {
    if (mapContainerRef.current) {
      const map = new MapLibreMap({
        container: mapContainerRef.current,
        style: `https://maps.geo.us-east-1.amazonaws.com/maps/v0/maps/LocationServiceMap/style-descriptor?key=${process.env.NEXT_PUBLIC_AWS_LOCATION}`,
        center: defaultCenter ? [defaultCenter.lng, defaultCenter.lat] : [0, 0],
        zoom: zoom,
      });

      mapRef.current = map;

      const navControl = new NavigationControl({
        showCompass: false,
        showZoom: true,
      });

      if (mapOptions.zoomControl) mapRef.current.addControl(navControl, 'bottom-right');

      mapRef.current.scrollZoom.enable();
      mapRef.current.touchZoomRotate.enable();
      mapRef.current.touchPitch.enable();
      mapRef.current.dragPan.enable();

      mapRef.current.getCanvas().style.cursor = 'grab';

      if (mapOptions?.fullscreenControl) {
        const fullscreenControl = new FullscreenControl();
        mapRef.current.addControl(fullscreenControl, 'top-right');
      }

      map.on('load', () => {
        setMapBounds();
        addStaticMarkers();
      });
    }
  };

  const addStaticMarkers = (): void => {
    if (!staticLocations || !mapRef.current) return;

    const bounds = new LngLatBounds();

    staticLocations.forEach((loc) => {
      const locationMarkerElement = document.createElement('div');
      locationMarkerElement.className = 'marker';

      // Render the custom marker component
      ReactDOM.render(
        <LocationMarkerSvg
          title={`Location: ${loc.lat}, ${loc.lng}`}
          hideEyeOnPin={false}
          markerIcon={'none'}
          focused={true}
        />,
        locationMarkerElement
      );

      locationMarkerElement.style.width = '40px';
      locationMarkerElement.style.height = '40px';

      if (mapRef.current) {
        new Marker({ element: locationMarkerElement })
          .setLngLat([loc.lng, loc.lat])
          .addTo(mapRef.current);
      }

      // Extend map bounds to include this location
      bounds.extend([loc.lng, loc.lat]);
    });

    // Fit the map bounds to include all static locations
    mapRef.current.fitBounds(bounds, {
      padding: 50,
      maxZoom: 14,
      duration: 1000,
    });
  };

  const setMapBounds = (): void => {
    if (!mapRef.current) return;

    const bounds = new LngLatBounds();

    if (staticLocations) {
      staticLocations.forEach((loc) => {
        bounds.extend([loc.lng, loc.lat]);
      });
    }

    mapRef.current.fitBounds(bounds, {
      padding: 100,
      maxZoom: 15,
      duration: 1000,
    });
  };

  useEffect(() => {
    if (staticLocations) {
      initializeMap();
    }

    return () => {
      if (mapRef.current) mapRef.current.remove();
    };
  }, [staticLocations]);

  return <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }} />;
}
