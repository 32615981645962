import React, { ReactElement, useEffect, useRef, useState, MutableRefObject } from 'react';
import { IComponentAssociateLocatorFields } from 'types/contentful';
import RichText from 'components/UI/Richtext';
import StaticMap from 'components/UI/StaticMap';

type Map = { lat: number; lng: number };

export default function AssociatesMap({
  heading,
  description,
  locationsFragments,
}: IComponentAssociateLocatorFields): ReactElement {
  const [locationMaps, setLocationMaps] = useState<Map[]>();
  const mapContainer = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (locationsFragments) {
      const newLocationMaps = locationsFragments.map((x) => {
        return { lat: x.fields.map.lat, lng: x.fields.map.lon };
      });
      setLocationMaps(newLocationMaps);
    }
  }, [locationsFragments]);

  return (
    <div className="flex flex-col justify-center text-center space-y-2 md:space-y-6 text-primary-actual py-10 md:px-12 lg:mx-16 px-4 lg:px-16 ">
      <div className="text-2xl lg:text-4xl mb-1 px-4 lg:px-16">{heading}</div>
      <div className="h-doctorMobile pb-2 lg:pb-0 relative relative" ref={mapContainer}>
        <StaticMap
          staticLocations={locationMaps}
          defaultCenter={{ lat: 37.7749, lng: -122.4194 }} // Default center on San Francisco
          zoom={10}
        />
      </div>
      <div className="flex flex-row flex-wrap lg:flex-no-wrap justify-center ">
        {locationsFragments?.map((x, index) => (
          <button
            key={index}
            className={`w-full flex-grow rounded-full border border-teal py-4 max-w-120 md:max-w-200 lg:max-w-300 m-1 hover:border-black hover:bg-neutral`}
          >
            <a href={x.fields.locations.fields.slug} className="capitalize button-text">
              {x.fields.name}
            </a>
          </button>
        ))}
      </div>
      {description && (
        <RichText
          document={description}
          classNames="px-2 leading-relaxed text-lg lg:text-2xl text-primary-actual"
        />
      )}
    </div>
  );
}
